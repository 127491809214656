/* eslint-disable */

import $ from 'jquery'

$(document).ready(function() {
    dhsv_vc_section()
})

function dhsv_vc_section() {
    $('.dhsv_vc_section').each(function() {
        let parent = this.parentNode
        let parentType = undefined

        if(parent.classList.contains('dhsv_vc_accordion'))
            parentType = 'accordion'
        else if(parent.classList.contains('tabs'))
            parentType = 'tabs'

        this.classList.add(parentType)
    })
}

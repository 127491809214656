import React from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'

var icon = 'search'
if ($('body').hasClass('bosch-io')) {
  icon = 'close'
}

class SearchIcon extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { active, activate, deactivate } = this.props

    return (
      <div className="nav-link search-icon">
        <i
          className={'boschicon-bosch-ic-' + (active ? 'close' : icon)}
          aria-hidden="true"
          onClick={active ? deactivate : activate}
        />
      </div>
    )
  }
}

SearchIcon.propTypes = {
  active: PropTypes.bool,
  activate: PropTypes.func,
  deactivate: PropTypes.func,
}

export default SearchIcon

/* global System */
// register component(s) with react habitat

export default {
  CalculatorOld2: () =>
    System.import(
      /* webpackChunkName: "CalculatorOld2" */
      './index'
    ),
}

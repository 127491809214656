import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Component from './component'

import { activate, deactivate } from '../../redux/modules/search'

export default connect(
  state => ({
    home_url: state.options.home_url,
    active: state.search.active,
  }),
  dispatch =>
    bindActionCreators(
      {
        activate,
        deactivate,
      },
      dispatch
    )
)(Component)

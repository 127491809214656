import React from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'
import globalData from '../../shared/globalData'

var placeholder = 'What are you looking for?'

if (
  ($('body').hasClass('bosch-io') && $('body').hasClass('language_de')) ||
  ($('body').hasClass('bosch-io-track-and-trace') &&
    $('body').hasClass('language_de'))
) {
  placeholder = 'Suche'
} else if (
  $('body').hasClass('bosch-io') ||
  $('body').hasClass('bosch-io-track-and-trace')
) {
  placeholder = 'Search'
}

class SearchBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { width: 0 }
    this.update = this.update.bind(this)

    window.addEventListener('resize', this.update)
  }

  componentDidMount() {
    this.update()
  }

  update() {
    this.setState({
      width: window.innerWidth,
    })
  }

  componentDidUpdate(prevProps) {
    const { active: wasActive } = prevProps
    const { active } = this.props

    const hasFocus = this.searchInput === document.activeElement

    if (wasActive && !active && hasFocus) {
      this.searchInput.blur()
    }

    if (active && this.state.width > 576) {
      this.searchInput.focus()
    }
  }

  render() {
    const {
      activate,
      deactivate,
      active,
      inputString,
      updateInput,
    } = this.props

    return (
      <div className={'searchbar' + (active ? ' active' : '')}>
        <div className="searchbar__container">
          <form
            className="searchbar__form"
            action={`${globalData.homeUrl}`}
            method="get"
          >
            <input
              aria-label={placeholder}
              placeholder={placeholder}
              name="s"
              type="text"
              ref={node => (this.searchInput = node)}
              value={inputString}
              autoComplete="off"
              onFocus={activate}
              onBlur={deactivate}
              onChange={({ currentTarget: { value } }) => updateInput(value)}
            />
            <button type="button" title="Search" tabIndex={-1}>
              {' '}
              <i className={'boschicon-bosch-ic-search'} />
            </button>
          </form>
        </div>
      </div>
    )
  }
}

SearchBar.propTypes = {
  activate: PropTypes.func,
  deactivate: PropTypes.func,
  inputString: PropTypes.string,
  updateInput: PropTypes.func,
  gotoSearch: PropTypes.func,
  active: PropTypes.bool,
}

export default SearchBar

import q from 'query-string'
import fromPairs from 'ramda/es/fromPairs'
import { utmParams } from './shared'

const { location, sessionStorage } = window

const domains = [
  'bosch.io',
  'www.bosch.io',
  'bosch-iot-suite.com',
  'www.bosch-iot-suite.com',
  'developer.bosch-iot-suite.com',
  'blog.bosch-si.com',
  'blog.bosch.io',
  'bosch-connected-world.com',
  'bosch-si.secure.force.com',
  'wcc.on24.com',
  'event.on24.com',
  'contact.bosch.io',
  'bcw-tickets.com',
  'gtmep.bosch.io',
  // this domain is used in our test
  'bos004b.dev.360vier.net',
  'bos006.dev.360vier.net',
]

const domainRegexPattern =
  '^https?:\\/\\/(' +
  domains.map(d => d.replace(/\./g, '\\.')).join('|') +
  ')(\\/|$)'

const domainRegex = new RegExp(domainRegexPattern)

const currentDomainRegex = new RegExp(`^https?:\\/\\/${location.host}(\\/|$)`)

export const startAppend = () => {
  const handler = e => {
    let el = e.target

    // go up the dom until we find an a tag
    while (el && el.parentElement && !el.matches('a[href]')) {
      el = el.parentElement
    }

    if (!el || !el.matches('a[href]')) return

    const href = el.attributes.href.value
    const target = el.attributes.target ? el.attributes.target.value : ''
    const willOpenInNewWindow = /_blank/.test(target)
    const isContextmenu = e.type === 'contextmenu'
    const isMiddleButton = e.type === 'auxclick'

    const [hrefWithoutHash, hash] = href.split('#')
    const [url, query] = hrefWithoutHash.split('?')

    const storedParamPairs = utmParams
      .map(k => [k, sessionStorage.getItem(k)])
      .filter(([, v]) => !!v)

    // bail if we're staying on the same domain
    if (
      // But only skip current domain link when it's not a new window because a new window is a new session.
      // Also the contextmenu and middle mouse button can be used to open a new window so we have to cover this, too.
      (currentDomainRegex.test(url) &&
        !willOpenInNewWindow &&
        !isContextmenu &&
        !isMiddleButton) ||
      // or the link's domain is not in our list
      !domainRegex.test(url) ||
      // or there are no stored utm parameters to forward
      !storedParamPairs.length
    ) {
      return
    }

    const hrefParams = q.parse(query)
    const storedParams = fromPairs(storedParamPairs)

    const finalParams = {
      ...storedParams,
      ...hrefParams,
    }

    const appendHash = hash ? '#' + hash : ''

    const finalUrl = `${url}?${q.stringify(finalParams)}${appendHash}`

    el.attributes.href.value = finalUrl
  }
  document.addEventListener('click', handler)
  document.addEventListener('auxclick', handler)
  document.addEventListener('contextmenu', handler)
}

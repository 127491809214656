/* global System */
import $ from 'jquery'

$(document).ready(function() {
  if ($('.dhsv_vc_progress').length > 0) {
    System.import(
      /* webpackChunkName: "dhsv_vc_progress" */
      './progress'
    )
  }
})

/* global System */

/*

when there are code blocks to highlight, load highlight.js and apply it to all code blocks.

*/

document.addEventListener('DOMContentLoaded', () => {
  const codeBlocks = document.querySelectorAll('pre code')

  if (!codeBlocks.length) {
    return
  }

  Promise.all([
    System.import('highlight.js'),
    System.import('../../styles/components/highlightjs.scss'),
  ]).then(([hljs]) =>
    codeBlocks.forEach(block => {
      hljs.highlightBlock(block)
    })
  )
})

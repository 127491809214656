import { createResponsiveStateReducer } from 'redux-responsive'
import { createSelector } from 'reselect'

// bootstrap $grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1920px);
export default createResponsiveStateReducer({
  xs: 575,
  sm: 767,
  md: 991,
  lg: 1199,
  xl: 1919,
})

export const sLayoutColumns = createSelector(
  state => state.browser.lessThan,
  lessThan => {
    if (lessThan.md) return 1
    if (lessThan.lg) return 2
    return 3
  }
)
export const sIsMobile = state => state.browser.lessThan.sm

export const sIsMD = state => state.browser.lessThan.lg

export const sIsLessThanXl = state => state.browser.lessThan.xl

$(document).ready(() => {
  const $pageAnchors = $('.page-anchors')

  if (!$pageAnchors.length) {
    return
  }

  $(window).on('scroll', () => {
    var i = 0
    var navLink = $('.page-anchors__item.nav-link')
    navLink.each(function() {
      var element = $(this)
      var scrolled = $(window).scrollTop()
      var id = element.attr('href')
      var eTop = $(id).offset().top

      $('[href="' + id + '"]').removeClass('active')

      if (eTop <= scrolled + 60) {
        i++
      }
    })

    if (i != 0) {
      var activeId = navLink[i - 1].getAttribute('href')
      $('[href="' + activeId + '"]').addClass('active')
    }
  })

  $(window).on('hashchange', function() {
    var hash = location.hash
    $pageAnchors.find('a').each(function() {
      var that = $(this)
      that[that.attr('href') === hash ? 'addClass' : 'removeClass']('active')
    })
  })
})

export const UPDATE_PRODUCTS = 'bos005calc/calculator/UPDATE_PRODUCTS'
export const UPDATE_INPUTS = 'bos005calc/calculator/UPDATE_INPUTS'
export const UPDATE_RESULTS = 'bos005calc/calculator/UPDATE_RESULTS'
export const UPDATE_INFO = 'bos005calc/calculator/UPDATE_INFO'
export const SWITCH_CURRENCY = 'bos005calc/calculator/SWITCH_CURRENCY'

export const initialState = {
  products: [],
  checkedProducts: [],
  inputValues: {},
  results: {
    total: 0,
  },
  info: {
    text: '',
    open: false,
  },
  currency: '$',
}

export default (state = initialState, action) => {
  const { type, payload = {} } = action

  switch (type) {
    case UPDATE_PRODUCTS:
      return {
        ...state,
        products: payload,
      }
    case UPDATE_INPUTS:
      return {
        ...state,
        inputValues: {
          ...state.inputValues,
          ...{ [payload.name]: payload.update },
        },
      }
    case UPDATE_RESULTS:
      return {
        ...state,
        results: {
          ...state.results,
          ...payload,
        },
      }
    case UPDATE_INFO:
      return {
        ...state,
        info: payload,
      }
    case SWITCH_CURRENCY:
      return {
        ...state,
        currency: state.currency === '$' ? '€' : '$',
      }
    default:
      return state
  }
}

export const updateProducts = payload => dispatch => {
  dispatch({
    type: UPDATE_PRODUCTS,
    payload,
  })

  if (payload.length < 1) {
    dispatch({
      type: UPDATE_RESULTS,
      payload: { total: 0 },
    })
  }
}

export const updateInputs = (name, update) => dispatch => {
  dispatch({
    type: UPDATE_INPUTS,
    payload: { name, update },
  })
}

export const updateResults = payload => dispatch => {
  dispatch({
    type: UPDATE_RESULTS,
    payload,
  })
}

export const updateInfo = (text, open) => dispatch => {
  if (open === undefined) {
    open = true
  }
  dispatch({
    type: UPDATE_INFO,
    payload: { text, open },
  })
}

export const switchCurrency = () => dispatch => {
  dispatch({
    type: SWITCH_CURRENCY,
  })
}

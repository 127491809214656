import $ from 'jquery'
//*********Main Menu*********
//***************************

// Main menu hover dropdown
$('.primary-nav .menu-item-has-children.level-0').hover(
  function() {
    var $that = $(this)
    if (!$that.hasClass('show') && !$('body').hasClass('mobile')) {
      $that.addClass('show').children('.dropdown-menu')
    }
  },
  function() {
    var $that = $(this)
    if ($that.hasClass('show') && !$('body').hasClass('mobile')) {
      setTimeout(function() {
        $that.removeClass('show').children('.dropdown-menu')
      }, 1000)
    }
  }
)

//Main Menu Close Icon
$('.primary-nav .menu-item-has-children.level-0').each(function() {
  $(this)
    .find('ul.dropdown-menu:first')
    .append(
      '<div class="close-icon"><i class="boschicon-bosch-ic-close"></i></div>'
    )
})

$('.primary-nav .dropdown-menu .close-icon').click(function() {
  var $that = $('.primary-nav .menu-item-has-children.level-0.show')
  $that.removeClass('show').children('.dropdown-menu')
})

//Main Menu Layout
$('.primary-nav .menu-item-has-children.level-1 ul.dropdown-menu').each(
  function() {
    var list_element = $(this).find('li')
    var count = $(this).find('li').length
    for (var i = 0; i < list_element.length; i += 4) {
      list_element.slice(i, i + 4).wrapAll("<li class='column'></li>")
    }
    if (count > 16) {
      $(this)
        .parent()
        .addClass('list_xxxl')
    } else if (count > 12) {
      $(this)
        .parent()
        .addClass('list_xxl')
    } else if (count > 8) {
      $(this)
        .parent()
        .addClass('list_xl')
    } else if (count > 4) {
      $(this)
        .parent()
        .addClass('list_l')
    }
  }
)

//*********Mobile Menu*********
//*****************************
$('.navbar-toggler').on('click', function() {
  $(this)
    .find('.navbar-toggler__icon')
    .toggleClass('boschicon-bosch-ic-list-view-mobile boschicon-bosch-ic-close')
})

$('.mobile-primary-nav .menu-item-has-children').each(function() {
  var parent = $(this)
  var parentName = parent.find('a').html()

  stickyOnScroll()
  $(window).scroll(function() {
    stickyOnScroll()
  })

  function stickyOnScroll() {
    const scrollTop = $(window).scrollTop()
    const $headerBottom = $('.header__bottom')
    const $header = $('header.header')
    const headerPosBottom = $header.height() - scrollTop

    parent.find('.dropdown-menu').css({
      top:
        headerPosBottom > $headerBottom.height()
          ? headerPosBottom - 2
          : $headerBottom.height() - 1,
    })
  }

  parent.append(
    '<i class="arrow-icon boschicon-bosch-ic-wide-angled-arrow"></i>'
  )

  parent.find('.arrow-icon').click(function() {
    parent.addClass('show')
    stickyOnScroll()
  })

  parent
    .find('.dropdown-menu:first')
    .prepend(
      '<li class="parent back menu-item"><i class="boschicon-bosch-ic-back-left"></i>' +
        parentName +
        '</li>'
    )

  parent.find('.parent.back').click(function() {
    parent.removeClass('show')
  })
})

mobileMenuHeight()
$(window).scroll(function() {
  mobileMenuHeight()
})

function mobileMenuHeight() {
  const scrollTop = $(window).scrollTop()
  const $headerBottom = $('.header__bottom')
  const $header = $('header.header')
  const headerPosBottom = $header.height() - scrollTop
  const windowHeigth = $(window).height()
  const menuOffset =
    headerPosBottom > $headerBottom.height()
      ? headerPosBottom - 2
      : $headerBottom.height() - 1
  $('#header-mobile-menu').css({
    height: windowHeigth - menuOffset,
  })
}

//*********Mobile Contact Buttons*********
//****************************************
function mobileContactButttons() {
  var contactButtonWrapper = $('.mobile-contact-button.contact-button-wrapper')
  var cbHeigth = $(
    '.mobile-contact-button.contact-button-wrapper .contact-button'
  ).outerHeight()

  $('.mobile-contact-button.button').click(function() {
    var button = $(this)
    if (contactButtonWrapper.hasClass('active')) {
      contactButtonWrapper.removeClass('active')
      button.find('i').removeClass('boschicon-bosch-ic-close')
      button.find('i').addClass('boschicon-bosch-ic-service-agent')
      contactButtonWrapper.animate({ height: 0 }, 500)
      $('.header .header__bottom').removeClass('contact_active')
    } else {
      contactButtonWrapper.addClass('active')
      button.find('i').removeClass('boschicon-bosch-ic-service-agent')
      button.find('i').addClass('boschicon-bosch-ic-close')
      contactButtonWrapper.animate({ height: cbHeigth }, 500)
      $('.header .header__bottom').addClass('contact_active')
    }
  })

  $('.menu-item.search').click(function() {
    if (contactButtonWrapper.hasClass('active')) {
      contactButtonWrapper.removeClass('active')
      $('.mobile-contact-button.button')
        .find('i')
        .removeClass('boschicon-bosch-ic-close')
      $('.mobile-contact-button.button')
        .find('i')
        .addClass('boschicon-bosch-ic-service-agent')
      contactButtonWrapper.animate({ height: 0 }, 100)
    }
  })
}

mobileContactButttons()

import $ from 'jquery'
import stickElement from '../components/stickit.js'
import debounce from 'lodash.debounce'

export default {
  init() {
    // JavaScript to be fired on all pages
    $('body').addClass('loaded')

    $(document).on('click', '.BoschPrivacySettingsV2__close', function(e) {
      e.preventDefault()
      // eslint-disable-next-line no-console
      console.log('BoschPrivacySettingsV2__close')
      $('.BoschPrivacySettingsV2__button--tertiary').trigger('click')
    })

    // Show the DOCK dialog
    function showDockPrivacySettings() {
      customElements.whenDefined('dock-privacy-settings')
      const dockPrivacySettings = document.querySelector(
        'dock-privacy-settings'
      )
      dockPrivacySettings.setAttribute('visible', 'true')
    }

    $(document).on('click', 'a.show-privacy-settings', function(e) {
      e.preventDefault()
      // eslint-disable-next-line no-console
      console.log('DOCKPrivacySettings__show')
      showDockPrivacySettings()
    })

    $(document).on('click', 'a[href*="show-privacy-settings"]', function(e) {
      e.preventDefault()
      // eslint-disable-next-line no-console
      console.log('DOCKPrivacySettings__show')
      showDockPrivacySettings()
    })

    removeSpaceOnHeroRow()

    function removeSpaceOnHeroRow() {
      const $hero = $('.vc_row .dhsv_vc_hero_section')
      const $row = $hero.parents('.vc_row')

      $row.addClass('only-bottom')
    }

    /* eslint-disable */
    function smooth_scroll(link, e) {
      if (e) e.preventDefault()
      var hash = false
      if (!link && window.location.hash) {
        hash = window.location.hash
      } else if (link && link.indexOf('#') == 0) {
        hash = link
      } else if (
        link &&
        location.pathname.replace(/^\//, '') ==
          link.pathname.replace(/^\//, '') &&
        location.hostname == link.hostname
      ) {
        hash = link.hash
      }

      let offset = 70
      const pageAnchors = $('.page-anchors')

      if (pageAnchors.length) {
        pageAnchors.each(function(index, el) {
          if ($(hash).offset().top > $(el).offset().top) {
            offset += $(el).outerHeight()
          }
        })
      }

      if (hash) {
        const $hashEl1 = $(hash)
        const $hashEl2 = $(`a[name="${hash.replace('#', '')}"]`)

        const $hashEl = $hashEl1.length ? $hashEl1 : $hashEl2

        if (!$hashEl.length) {
          return
        }

        $('html, body').animate(
          {
            scrollTop: $hashEl.offset().top - offset,
          },
          600
        )
        history.replaceState(null, null, hash)
      }
      $(window).trigger('hashchange')
    }
    /* eslint-enable */

    $('[data-toggle="tooltip"]').tooltip()
    $(document).on(
      'click',
      'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-toggle],[data-vc-accordion],[role="tab"])',
      function(e) {
        smooth_scroll($(this).attr('href'), e)
      }
    )

    $(window).load(function() {
      if (window.location.hash) {
        smooth_scroll(window.location.hash)
      }
    })

    stickElement('.header__bottom')
    stickElement('.page-anchors')

    // add class 'external' to all external links
    // $('.content a:not(.btn, .post)')
    //   .filter(function() {
    //     return this.hostname && this.hostname !== location.hostname
    //   })
    //   .addClass('external external-link')
    //   .append('<i class="boschicon-bosch-ic-externallink"></i>')

    // resize full width row
    function resizeFullWidthRow() {
      $('[data-vc-full-width="true"]').each(function() {
        $(this).css({
          width: document.body.clientWidth,
          marginLeft: -document.body.clientWidth / 2,
        })
        if (!$(this).attr('data-vc-stretch-content')) {
          // let padding =
          //   (document.body.clientWidth - $('.container.wrap').outerWidth()) / 2
          // $(this).css({
          //   'padding-left': padding,
          //   'padding-right': padding,
          // })
        }
      })
    }

    resizeFullWidthRow()

    $('.comments-area').click(function() {
      $(this)
        .find('a')
        .attr('target', '_blank')
    })

    $('.comment-form-comment').each(function() {
      var wraper = $(this)
      var field = $(this).find('#comment')
      field.on('keyup cange', function() {
        if ($(this).val() != '') {
          wraper.addClass('hasVal')
        } else {
          wraper.removeClass('hasVal')
        }
      })
      field.focus(function() {
        wraper.addClass('hasFocus')
      })
      field.blur(function() {
        wraper.removeClass('hasFocus')
      })
    })

    dhsvComments()
    mobileMenu()

    // wir warten ganz kurz damit, den Event-Handler anzuschließen damit,
    // wenn man runtergescrollt hat, die seite neu lädt, das erste scroll-event
    // übersprungen wird. Sonst verschwindert der copyright-hinweis sofort wieder,
    // ohne dass man tatsächlich scrollt
    window.setTimeout(function() {
      $(window).scroll(debounce(handleScroll, 100))
    }, 10)

    function handleScroll() {
      $('.bosch-copyright-notice').addClass('did-scroll')

      const scrollTop = $(window).scrollTop()
      // Sticky Header
      // if (scrollTop > 1) {
      //   $('header.header').addClass('scrolled')
      //   $('.socialtabs').addClass('scrolled')
      // } else {
      //   $('header.header').removeClass('scrolled')
      //   $('.socialtabs').removeClass('scrolled')
      // }
      // Social Tabs
      var singleContent = $('.singlecontent')
      var tabs = $('.socialtabs')
      tabs.each(function() {
        if (
          singleContent.length > 0 &&
          singleContent.offset().top < scrollTop
        ) {
          tabs.addClass('showtabs')
        } else {
          tabs.removeClass('showtabs')
        }
      })
      readingBar()
    }

    function mobileMenuOffset() {
      let menuOffset =
        $('.header .supergraphic').outerHeight() +
        $('.header .header-bottom').outerHeight()
      $('.mobile_menu').css({
        top: menuOffset,
      })
    }

    // Set dynamic position for 'Cookie Policy' button
    const ccRevokePosition = () => {
      const ccRevoke = $('.cc-revoke')
      if ($(document).width() <= 1440) {
        ccRevoke.addClass('cc-bottom')
        ccRevoke.removeClass('cc-top')
      } else {
        ccRevoke.removeClass('cc-bottom')
        ccRevoke.addClass('cc-top')
      }
    }

    // Set dynamic position for 'Cookie Policy' button
    ccRevokePosition()

    $(window).resize(function() {
      resizeFullWidthRow()

      setTimeout(function() {
        if (
          $(window).width() >= 993 &&
          $('.mobile_toggle').hasClass('activetoggle')
        ) {
          $('.mobile_toggle').trigger('click')
        } else {
          mobileMenuOffset()
        }
      }, 500)

      // Set dynamic position for 'Cookie Policy' button
      ccRevokePosition()
    })

    function mobileMenu() {
      let prevScrollOffset = 0
      $('.mobile_toggle').click(function() {
        $('.socialtabs').removeClass('activemenu')
        $('.share_toggle').removeClass('activetoggle')
        if ($(this).hasClass('activetoggle')) {
          $(this).removeClass('activetoggle')
          $('html').removeClass('menuactive')
          $('.mobile_menu').removeClass('activemenu')
          $(document).scrollTop(prevScrollOffset)
        } else {
          prevScrollOffset = $(document).scrollTop()
          $(this).addClass('activetoggle')
          $('html').addClass('menuactive')
          $('.mobile_menu').addClass('activemenu')
          mobileMenuOffset()
        }
      })

      $('.share_toggle').click(function() {
        $('.mobile_menu').removeClass('activemenu')
        $('.mobile_toggle').removeClass('activetoggle')
        if ($(this).hasClass('activetoggle')) {
          $(this).removeClass('activetoggle')
          $('.socialtabs').removeClass('activemenu')
        } else {
          $(this).addClass('activetoggle')
          $('.socialtabs').addClass('activemenu')
        }
      })
    }

    function readingBar() {
      var scrollTop = $(window).scrollTop()
      $('.singlecontent').each(function() {
        var h = $(this).height()
        var p1 = 100 / h
        var p2 = p1 * scrollTop
        if (p2 < 100) {
          $('header .blog_duration').css('width', p2 + '%')
          $('header .blog_duration').css('height', '5px')
        } else if (p2 < 120) {
          $('header .blog_duration').css('width', 100 + '%')
          $('header .blog_duration').css('height', '5px')
        } else {
          $('header .blog_duration').css('width', 100 + '%')
          $('header .blog_duration').css('height', '0px')
        }
      })
    }

    function dhsvComments() {
      $('#comments').each(function() {
        var listH = $(this)
          .find('.comments_list')
          .outerHeight()
        var formH = $(this)
          .find('.comment-respond')
          .outerHeight()

        $(this)
          .find('.comments_list')
          .height(0)
        $(this)
          .find('.comment-respond')
          .height(0)

        $('.comments-area').addClass('comments_load')

        $(this)
          .find('.comments_head span')
          .click(function() {
            if (
              $(this)
                .parents('#comments')
                .hasClass('active')
            ) {
              $(this)
                .parents('#comments')
                .removeClass('active')
              $('.comments_list').height(0)
              $('.comment-respond').height(0)
            } else {
              $(this)
                .parents('#comments')
                .addClass('active')
              $('.comments_list').height(listH)
              $('.comment-respond').height(formH)
            }
            $(this)
              .parents('#comments')
              .find('.comment_item')
              .click(function() {
                $('.comments_list').height('auto')
                $('.comment-respond').height('auto')
              })
          })
      })
    }
    function dhsvTutorialsCode() {
      $(document).ready(function() {
        if ($('.single-tutorials .entry-content pre ')) {
          $('.single-tutorials .entry-content pre ').each(function() {
            var $item = $(this)
            var $code = $item.find('code')
            var height = $code.outerHeight()
            $item.append('<div class="copy-button"></div>')
            $item.find('.copy-button').click(function() {
              var $temp = $('<input>')
              $('body').append($temp)
              $temp.val($item.find('code').text()).select()
              document.execCommand('copy')
              $temp.remove()
            })
            $item.append('<div class="color-change-button"></div>')
            $item.find('.color-change-button').click(function() {
              if ($item.hasClass('light')) {
                $item.find('code').removeClass('light')
                $item.removeClass('light')
                $(this).removeClass('light')
              } else {
                $item.find('code').addClass('light')
                $item.addClass('light')
                $(this).addClass('light')
              }
            })
            if (height >= 206) {
              $item.append('<div class="more-button"></div>')
              $item.find('code').addClass('long_code')
              $item.find('code').each(function() {
                var height = $(this).outerHeight()
                $(this).attr('data-height', height)
                $(this).height(216)
              })
              $item.find('.more-button').click(function() {
                if ($item.find('code').hasClass('visible')) {
                  $item.find('code').removeClass('visible')
                  $item.find('code').height('216')
                  $(this).removeClass('visible')
                } else {
                  $item.find('code').addClass('visible')
                  $item
                    .find('code')
                    .height($item.find('code').attr('data-height'))
                  $(this).addClass('visible')
                }
              })
            }
          })
        }
      })
    }
    dhsvTutorialsCode()

    function dhsvOpenShare() {
      // $('body').on('click', '.contact-button a', function() {
      //   var href = $(this).attr('href')
      //   if (href == '#openshare') {
      //     $('.socialtabs').toggleClass('showtabs')
      //   }
      // })

      $('body').on('click', '.mobile-share-button', function() {
        $(this)
          .find('i')
          .toggleClass('boschicon-bosch-ic-share')
          .toggleClass('boschicon-bosch-ic-close')
        $('.socialtabs').toggleClass('showtabs')
      })
    }

    dhsvOpenShare()

    $('body').on('click', '.cc-revoke', function() {
      $('html, body')
        .stop()
        .animate({ scrollTop: 0 }, 500, 'swing', function() {
          console.log('scroll top') // eslint-disable-line
        })
    })
    $('.dhsv-go-to-top-button').click(function() {
      $('html, body').animate({ scrollTop: 0 }, 600)
      return false
    })

    const toggleLanguageSwitcher = function() {
      var $menuitem = $(this)
      if ($menuitem.hasClass('active')) {
        $menuitem.removeClass('active')
      } else {
        $menuitem.addClass('active')
      }
    }

    $('.wpml-ls-menu-item.wpml-ls-current-language')
      .on('click', toggleLanguageSwitcher)
      .on('keypress', ({ key, currentTarget }) => {
        if (['Enter'].includes(key)) {
          toggleLanguageSwitcher.apply(currentTarget)
        }
      })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Component from './component'

import { deactivate } from '../../redux/modules/search'

export default connect(
  state => ({
    active: state.search.active,
  }),
  dispatch => bindActionCreators({ deactivate }, dispatch)
)(Component)

// import external dependencies
import 'jquery'

// import everything from autoload
import './autoload/_bootstrap.js' // eslint-disable-line

// import vc-elements javascript files
import '../vc_elements/_new/main.js'; import '../vc_elements/accordion/main.js'; import '../vc_elements/header_animation/main.js'; import '../vc_elements/hero_section/main.js'; import '../vc_elements/link_bar/main.js'; import '../vc_elements/page_anchors/main.js'; import '../vc_elements/progress/main.js'; import '../vc_elements/section/main.js'; import '../vc_elements/slider/main.js'; import '../vc_elements/teaser_area_extended/main.js'; import '../vc_elements/teaser_area/main.js'; import '../vc_elements/teaser_io_slider/main.js'; import '../vc_elements/teaser_slider/main.js'; import '../vc_elements/tile_bar/main.js'; import '../vc_elements/updates/main.js'; import '../vc_elements/video/main.js' // eslint-disable-line

// start forwarding utm query params
import './init/utm-forward'

import './init/gtm'

import './init/linkedin'

// import react habitat and react components
import './init/react'

import './util/syntax-highlighting'

// import local dependencies
import Router from './util/Router'
import common from './routes/common'
import home from './routes/home'
import aboutUs from './routes/about'
import singlePost from './routes/singlePost'
import menu from './routes/menu'

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  // blog posts
  singlePost,
  //Menu
  menu,
})

// Load Events
jQuery(document).ready(() => routes.loadEvents())

/* eslint-disable */

import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_accordion()
})

function dhsv_vc_accordion() {
  $('.dhsv_vc_accordion').each(function(index) {
    const accordionId = `accordion-${index}`
    this.id = accordionId

    $(this)
      .children()
      .each(function(index1, value) {
        if (!value.id) {
          value.id = `${accordionId}_section-${index1}`
        }

        value.addEventListener('click', function(event) {
          const current = value
          const accordion = getParentAccordion(event.target)
          const buttons = accordion.querySelectorAll('.head')

          buttons.forEach(element => {
            if (element.parentNode.id != current.id) {
              element.classList.remove('active')
              togglePanel(element.parentNode, false)
            } else {
              /* Toggle between adding and removing the "active" class,
                        to highlight the button that controls the panel */
              element.classList.toggle('active')
              togglePanel(
                element.parentNode,
                element.classList.contains('active')
              )
            }
          })
        })
      })
  })

  // when a section id is found in the location hash, open the corresponding section and scroll there
  const { hash } = window.location
  const matches = hash.match(/#(.*)/)
  const selectedSection = matches ? matches[1] : null

  if (selectedSection) {
    const el = document.getElementById(selectedSection)
    if (el) {
      togglePanel(el, true)
      el.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }
}

function getParentAccordion(source) {
  const type = source.tagName

  if (type === 'BUTTON') return source.parentNode.parentNode
  if (type === 'I') return source.parentNode.parentNode.parentNode
  return undefined
}

function togglePanel(source, show) {
  /* Toggle between hiding and showing the active panel */
  const panel = source.lastElementChild
  if (!panel) {
    return
  }
  if (!show) {
    panel.style.maxHeight = null
  } else {
    panel.style.maxHeight = panel.scrollHeight + 'px'
  }
}

// lazy load helper for vc elements etc.
// just put the lazyload class on your element and call this function like that:
//
// lazyloadModule('dhsv_vc_slider', () =>
//   System.import(
//     /* webpackChunkName: "dhsv_vc_slider" */
//     './slider'
//   )
// )
//
// now your slider script will load when scrolling to it.

export default (className, loadModule) => {
  // load module right away if the lazybeforeunveil event has already passed
  if (
    document.querySelector(`.${className}.lazyloaded,.${className}.lazyloading`)
  ) {
    loadModule()
    return
  }

  // otherwise listen for event and load module then
  document.addEventListener('lazybeforeunveil', function({ target }) {
    if (target.classList.contains(className)) {
      loadModule()
    }
  })
}

import dissoc from 'ramda/es/dissoc'
import omit from 'ramda/es/omit'

export const INIT = 'bos005calc/iotcalculator/INIT'
export const UPDATE_FILTER = 'bos005calc/iotcalculator/UPDATE_FILTER'
export const UPDATE_FIELD = 'bos005calc/iotcalculator/UPDATE_FIELD'
export const UPDATE_RESULTS = 'bos005calc/iotcalculator/UPDATE_RESULTS'
export const SWITCH_CURRENCY = 'bos005calc/iotcalculator/SWITCH_CURRENCY'
export const RESET = 'bos005calc/iotcalculator/RESET'

export const initialState = {
  products: {},
  informations: {},
  filters: {
    additionals: [],
  },
  fields: {},
  results: {
    sums: {},
    total: {
      monthly: 0,
      yearly: 0,
      yearly_per_device: 0,
    },
  },
  currency: '$',
}

export default (state = initialState, action) => {
  const { type, payload = {} } = action

  switch (type) {
    case INIT:
      return {
        ...state,
        ...payload,
      }
    case UPDATE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [payload.name]: payload.value,
        },
      }
    case UPDATE_FIELD:
      return {
        ...state,
        fields: payload.value
          ? {
              ...state.fields,
              [payload.name]: payload.value,
            }
          : dissoc(payload.name, state.fields),
      }
    case UPDATE_RESULTS:
      return {
        ...state,
        results: payload,
      }
    case SWITCH_CURRENCY:
      return {
        ...state,
        currency: state.currency === '$' ? '€' : '$',
      }
    case RESET:
      return {
        ...state,
        fields: initialState.fields,
      }
    default:
      return state
  }
}

export const init = data => dispatch => {
  dispatch({
    type: INIT,
    payload: data,
  })
}

export const updateFilter = (name, value) => dispatch => {
  dispatch({
    type: UPDATE_FILTER,
    payload: { name, value },
  })

  dispatch(updateResults())
}

export const updateField = (name, value) => dispatch => {
  dispatch({
    type: UPDATE_FIELD,
    payload: { name, value },
  })

  dispatch(updateResults())
}

export const updateResults = () => (dispatch, getState) => {
  const state = getState()
  const {
    products,
    informations: { conversion },
    fields,
    filters: { capability, additionals },
    currency,
  } = state.iotcalculator

  if (!capability) {
    return
  }

  const conversionRatio = currency === '€' ? parseFloat(conversion) : 1
  const stringLocale = currency === '€' ? 'de-DE' : 'en-US'

  const checkMin = id => {
    if ([2, 6, 9, 11, 13, 15].includes(id)) {
      if (fields[id] < 2) {
        return 2
      }
    }
    return fields[id]
  }

  const keyToRangeKey = key => {
    if (
      [
        'digital_twin_update_device_to_cloud_size',
        'digital_twin_update_cloud_to_device_size',
        'software_update_related_digital_twin_updates_size',
      ].includes(key)
    ) {
      return 'digital_twin_update_size'
    } else if (
      [
        'digital_twin_update_device_to_cloud_transaction',
        'digital_twin_update_cloud_to_device_transaction',
        'software_update_related_digital_twin_updates_transaction',
      ].includes(key)
    ) {
      return 'digital_twin_update_transaction'
    } else if (
      [
        'live_message_device_to_cloud_size',
        'live_message_cloud_to_device_size',
        'software_update_related_live_messages_size',
      ].includes(key)
    ) {
      return 'live_message_size'
    } else if (
      [
        'live_message_device_to_cloud_transaction',
        'live_message_cloud_to_device_transaction',
        'software_update_related_live_messages_transaction',
      ].includes(key)
    ) {
      return 'live_message_transaction'
    } else if (['software_update_related_notifications_size'].includes(key)) {
      return 'digital_twin_notification_size'
    } else if (
      ['software_update_related_notifications_transaction'].includes(key)
    ) {
      return 'digital_twin_notification_transaction'
    }
    return key
  }

  const cost = (product, key, calculation) => {
    if (!calculation) {
      return 0
    }

    let kb = 0

    if (
      [
        'digital_twin_update_device_to_cloud_size',
        'digital_twin_update_device_to_cloud_transaction',
        'digital_twin_update_cloud_to_device_size',
        'digital_twin_update_cloud_to_device_transaction',
      ].includes(key)
    ) {
      kb = checkMin(6)
      if (!kb) {
        return 0
      }
    } else if (
      [
        'live_message_device_to_cloud_size',
        'live_message_device_to_cloud_transaction',
        'live_message_cloud_to_device_size',
        'live_message_cloud_to_device_transaction',
      ].includes(key)
    ) {
      kb = checkMin(9)
      if (!kb) {
        return 0
      }
    } else if (
      [
        'digital_twin_notification_size',
        'digital_twin_notification_transaction',
      ].includes(key)
    ) {
      kb = checkMin(11)
      if (!kb) {
        return 0
      }
    } else if (
      ['digital_twin_read_size', 'digital_twin_read_transaction'].includes(key)
    ) {
      kb = checkMin(13)
      if (!kb) {
        return 0
      }
    } else if (
      [
        'software_update_related_digital_twin_updates_size',
        'software_update_related_digital_twin_updates_transaction',
        'software_update_related_live_messages_size',
        'software_update_related_live_messages_transaction',
        'software_update_related_notifications_size',
        'software_update_related_notifications_transaction',
      ].includes(key)
    ) {
      kb = 3
    }

    const ranges = products[product].calculations.find(
      c => c.name.value === keyToRangeKey(key)
    ).ranges

    const value = incremental => {
      if (!incremental) {
        if (
          kb &&
          [
            'digital_twin_update_device_to_cloud_size',
            'digital_twin_update_cloud_to_device_size',
            'live_message_device_to_cloud_size',
            'live_message_cloud_to_device_size',
            'digital_twin_notification_size',
            'digital_twin_read_size',
            'software_update_related_digital_twin_updates_size',
            'software_update_related_live_messages_size',
            'software_update_related_notifications_size',
          ].includes(key)
        ) {
          return calculation * 2
        }
      } else {
        if (
          kb &&
          [
            'digital_twin_update_device_to_cloud_size',
            'digital_twin_update_cloud_to_device_size',
            'live_message_device_to_cloud_size',
            'live_message_cloud_to_device_size',
            'digital_twin_notification_size',
            'digital_twin_read_size',
            'software_update_related_digital_twin_updates_size',
            'software_update_related_live_messages_size',
            'software_update_related_notifications_size',
          ].includes(key)
        ) {
          return calculation * 2 * Math.ceil((kb - 2) / 2)
        }
        if (
          kb &&
          [
            'digital_twin_update_device_to_cloud_transaction',
            'digital_twin_update_cloud_to_device_transaction',
            'live_message_device_to_cloud_transaction',
            'live_message_cloud_to_device_transaction',
            'digital_twin_notification_transaction',
            'digital_twin_read_transaction',
            'software_update_related_digital_twin_updates_transaction',
            'software_update_related_live_messages_transaction',
            'software_update_related_notifications_transaction',
          ].includes(key)
        ) {
          return calculation * Math.ceil((kb - 2) / 2)
        }
      }
      return calculation
    }

    const calcRangeCosts = incremental => {
      const priceKey = incremental ? 'price_increment' : 'price'
      const val = value(incremental)

      return ranges.map((range, i) => {
        if (!range[priceKey]) {
          return 0
        }
        if (i === 0) {
          if (range.max) {
            return Math.min(val, range.max) * (range[priceKey] / range.per)
          } else {
            return val * (range[priceKey] / range.per)
          }
        } else {
          if (val > ranges[i - 1].max) {
            if (range.max) {
              return (
                (Math.min(val, range.max) - ranges[i - 1].max) *
                (range[priceKey] / range.per)
              )
            } else {
              return (val - ranges[i - 1].max) * (range[priceKey] / range.per)
            }
          }
          return 0
        }
      })
    }

    const costs = calcRangeCosts(false)
    const incrementalCosts = calcRangeCosts(true)

    console.log(key) //eslint-disable-line
    console.log(value()) //eslint-disable-line
    console.log(costs) //eslint-disable-line
    console.log(incrementalCosts) //eslint-disable-line

    return (
      costs.reduce((a, b) => a + b * conversionRatio, 0) +
      incrementalCosts.reduce((a, b) => a + b * conversionRatio, 0)
    )
  }

  const devices = fields[1] ? fields[1] : 0

  const plans = {}

  const sums = {}
  const totals = {}

  new Array(...capability, ...additionals).forEach(product => {
    let tmpCalc = {}
    let tmpCosts = {}

    let bestPlan = false

    if ('calculations' in products[product]) {
      tmpCosts = products[product].calculations
        .map(item => item.name.value)
        .reduce((o, key) => ({ ...o, [key]: 0 }), {})
    } else {
      //console.log(products[product]) //eslint-disable-line
    }

    if (product === 'suite') {
      const toOmit = [
        'digital_twin_update_size',
        'digital_twin_update_transaction',
        'live_message_size',
        'live_message_transaction',
      ]

      const toAdd = [
        'digital_twin_update_device_to_cloud_size',
        'digital_twin_update_device_to_cloud_transaction',
        'digital_twin_update_cloud_to_device_size',
        'digital_twin_update_cloud_to_device_transaction',
        'live_message_device_to_cloud_size',
        'live_message_device_to_cloud_transaction',
        'live_message_cloud_to_device_size',
        'live_message_cloud_to_device_transaction',
        'software_update_related_digital_twin_updates_size',
        'software_update_related_digital_twin_updates_transaction',
        'software_update_related_live_messages_size',
        'software_update_related_live_messages_transaction',
        'software_update_related_notifications_size',
        'software_update_related_notifications_transaction',
      ]

      tmpCosts = omit(toOmit)(tmpCosts)

      tmpCosts = toAdd.reduce((o, key) => ({ ...o, [key]: 0 }), tmpCosts)

      tmpCalc.digital_twin_storage = (devices * checkMin(2)) / 1000
      tmpCalc.device_connection_time =
        devices * 60 * 24 * 30 * (fields[3] / 100)
      tmpCalc.digital_twin_update_device_to_cloud_size = devices * fields[4]
      tmpCalc.digital_twin_update_device_to_cloud_transaction =
        devices * fields[4]
      tmpCalc.digital_twin_update_cloud_to_device_size = devices * fields[5]
      tmpCalc.digital_twin_update_cloud_to_device_transaction =
        devices * fields[5]
      tmpCalc.live_message_device_to_cloud_size = devices * fields[7]
      tmpCalc.live_message_device_to_cloud_transaction = devices * fields[7]
      tmpCalc.live_message_cloud_to_device_size = devices * fields[8]
      tmpCalc.live_message_cloud_to_device_transaction = devices * fields[8]
      tmpCalc.digital_twin_notification_size = devices * fields[10]
      tmpCalc.digital_twin_notification_transaction = devices * fields[10]
      tmpCalc.digital_twin_read_size = devices * fields[12]
      tmpCalc.digital_twin_read_transaction = devices * fields[12]
      tmpCalc.artifact_download = devices * fields[14] * fields[15] * 1000
      tmpCalc.artifact_storage = fields[16]
      tmpCalc.software_update_related_digital_twin_updates_size =
        devices * fields[14] * 5
      tmpCalc.software_update_related_digital_twin_updates_transaction =
        devices * fields[14] * 5
      tmpCalc.software_update_related_live_messages_size =
        devices * fields[14] * 1
      tmpCalc.software_update_related_live_messages_transaction =
        devices * fields[14] * 1
      tmpCalc.software_update_related_notifications_size =
        devices * fields[14] * 1
      tmpCalc.software_update_related_notifications_transaction =
        devices * fields[14] * 1
      tmpCalc.device_management_actions = devices * fields[14]

      tmpCosts = Object.keys(tmpCosts).reduce(
        (o, key) => ({
          ...o,
          [key]: cost(product, key, tmpCalc[key]),
        }),
        tmpCosts
      )
    }

    if (product === 'insights') {
      tmpCalc.devices = devices
      tmpCalc.processed_data_volume = fields[20] * 1000 * fields[21]
      tmpCalc.raw_data_volume = fields[17] * 1000 * fields[19]
      tmpCalc.data_pipeline = fields[22]
      tmpCalc.mails = fields[24]
      tmpCalc.sms = fields[25]
      tmpCalc.ui_read_tickets = fields[29]
      tmpCalc.api_read_tickets = fields[30]
      tmpCalc.domain_decoding_file = fields[31]
      tmpCalc.support_plan = fields[32]

      tmpCalc = Object.keys(tmpCalc).reduce(
        (o, key) => ({ ...o, [key]: o[key] ? o[key] : 0 }),
        tmpCalc
      )
    } else if (product === 'rollouts') {
      tmpCalc.devices = devices
      tmpCalc.data_storage = fields[28]
      tmpCalc.data_transfer = (devices * fields[26] * fields[27]) / 1000 / 12

      tmpCalc = Object.keys(tmpCalc).reduce(
        (o, key) => ({ ...o, [key]: o[key] ? o[key] : 0 }),
        tmpCalc
      )
    }

    if (['insights', 'rollouts'].includes(product)) {
      const tmpPlans = products[product].plans.filter((plan, i) => {
        if (product === 'insights') {
          if (i === 0) {
            if (
              parseFloat(plan['limits_' + product].devices) >=
                tmpCalc.devices &&
              parseFloat(plan['limits_' + product].processed_data_volume) >=
                tmpCalc.processed_data_volume &&
              parseFloat(plan['limits_' + product].raw_data_volume) >=
                tmpCalc.raw_data_volume &&
              parseFloat(plan['limits_' + product].data_pipeline) >=
                tmpCalc.data_pipeline &&
              parseFloat(plan['limits_' + product].mails) >= tmpCalc.mails &&
              parseFloat(plan['limits_' + product].sms) >= tmpCalc.sms &&
              parseFloat(plan['limits_' + product].ui_read_tickets) >=
                tmpCalc.ui_read_tickets &&
              parseFloat(plan['limits_' + product].api_read_tickets) >=
                tmpCalc.api_read_tickets &&
              parseFloat(plan['limits_' + product].domain_decoding_file) >=
                tmpCalc.domain_decoding_file &&
              !tmpCalc.support_plan
            ) {
              return true
            }
          } else {
            return true
          }
        } else if (product === 'rollouts') {
          if (
            parseFloat(plan['limits_' + product].devices) >=
              parseFloat(tmpCalc.devices) &&
            parseFloat(plan['limits_' + product].data_storage) >=
              parseFloat(tmpCalc.data_storage) &&
            parseFloat(plan['limits_' + product].data_transfer) >=
              parseFloat(tmpCalc.data_transfer)
          ) {
            return true
          }
        }
      })
      if (!tmpPlans.length) {
        tmpPlans[0] =
          products[product].plans[products[product].plans.length - 1]
      }

      bestPlan = tmpPlans
        .map(plan => {
          let tmpSum = 0
          let tmpAdditional = {}
          let tmpAdditionalInfo = {}
          let tmpAdditionalInfoMax = {}
          let tmpCallForPrice = false

          Object.keys(tmpCalc).forEach(key => {
            const value = tmpCalc[key]

            if (['support_plan'].includes(key)) {
              return
            }

            tmpAdditional[key] = 0

            if (value > plan['limits_' + product][key]) {
              tmpAdditional[key] = products[product].additionals[key].ranges
                .map((range, i) => {
                  if (
                    range.max &&
                    plan['limits_' + product][key] >= parseInt(range.max)
                  ) {
                    return 0
                  }
                  if (i === 0) {
                    if (range.max) {
                      return (
                        (Math.min(value, range.max) -
                          plan['limits_' + product][key]) *
                        (range.price / range.per)
                      )
                    } else {
                      const prc = (() => {
                        if (key === 'processed_data_volume') {
                          if (
                            plan.title === 'Starter' ||
                            plan.title === 'Standard'
                          ) {
                            return 0.00168
                          }
                          if (plan.title === 'Premium') {
                            return 0.000672
                          }
                        }

                        if (key === 'raw_data_volume') {
                          if (
                            plan.title === 'Starter' ||
                            plan.title === 'Standard'
                          ) {
                            return 0.000744
                          }
                          if (plan.title === 'Premium') {
                            return 0.000252
                          }
                        }

                        return range.price
                      })()
                      return (
                        (value - plan['limits_' + product][key]) *
                        (prc / range.per)
                      )
                    }
                  } else {
                    if (
                      value >
                      products[product].additionals[key].ranges[i - 1].max
                    ) {
                      if (range.call_for_price) {
                        tmpCallForPrice = true
                        return 0
                      }
                      if (range.max) {
                        return (
                          (Math.min(value, range.max) -
                            products[product].additionals[key].ranges[i - 1]
                              .max) *
                          (range.price / range.per)
                        )
                      } else {
                        return (
                          (value -
                            products[product].additionals[key].ranges[i - 1]
                              .max) *
                          (range.price / range.per)
                        )
                      }
                    }
                    return 0
                  }
                })
                .reduce((a, b) => {
                  return a + (!isNaN(b) ? b : 0)
                }, 0)

              /* ADDITIONAL INFO */
              tmpAdditionalInfo[key] =
                '100% included capacity used \n(Total ' +
                products[product].additionals[key].title +
                ': '

              tmpAdditionalInfo[key] += value.toLocaleString(stringLocale, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })
              if (['processed_data_volume', 'raw_data_volume'].includes(key)) {
                tmpAdditionalInfo[key] += ' MB'
              }
              if (['data_storage', 'data_transfer'].includes(key)) {
                tmpAdditionalInfo[key] += ' GB'
              }
              tmpAdditionalInfo[key] += ')'
              /* ADDITIONAL INFO END */
              tmpAdditionalInfoMax[key] = true
            } else {
              /* ADDITIONAL INFO */
              const percent =
                value / plan['limits_' + product][key]
                  ? value / plan['limits_' + product][key]
                  : 0

              console.log(percent) //eslint-disable-line
              tmpAdditionalInfo[key] =
                (percent * 100).toLocaleString(stringLocale, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                }) +
                '% included capacity used \n(Total ' +
                products[product].additionals[key].title +
                ': '
              tmpAdditionalInfo[key] += value.toLocaleString(stringLocale, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })
              if (['processed_data_volume', 'raw_data_volume'].includes(key)) {
                tmpAdditionalInfo[key] += ' MB'
              }
              if (['data_storage', 'data_transfer'].includes(key)) {
                tmpAdditionalInfo[key] += ' GB'
              }
              tmpAdditionalInfo[key] += ')'
              /* ADDITIONAL INFO END */
            }

            tmpAdditional[key] = tmpAdditional[key] * conversionRatio
          })
          Object.keys(tmpAdditional).forEach(aKey => {
            tmpSum += tmpAdditional[aKey]
          })
          const basePrice = parseInt(plan.base_price) * conversionRatio
          tmpSum += basePrice

          return {
            title: plan.title,
            basePrice: basePrice,
            sum: tmpSum,
            costs: Object.keys(tmpAdditional).reduce(
              (o, key) => ({
                ...o,
                [key]: {
                  value: tmpAdditional[key],
                  title: products[product].additionals[key].title,
                  info: tmpAdditionalInfo[key],
                  infoMax: tmpAdditionalInfoMax[key],
                },
              }),
              {}
            ),
            callForPrice: tmpCallForPrice,
          }
        })
        .map(plan => {
          if (product === 'insights') {
            const support_plan = (() => {
              let value = 0
              let info = ''

              if (!tmpCalc.support_plan) {
                if (plan.title === 'Free') {
                  info = 'Community'
                } else if (plan.title === 'PayAsYouGo') {
                  info = 'Basic'
                } else {
                  info = 'Bronze'
                }
              } else {
                info = tmpCalc.support_plan

                if (
                  plan.title === 'PayAsYouGo' &&
                  tmpCalc.support_plan === 'Bronze'
                ) {
                  const support_plan_rate = 100 * conversionRatio
                  value =
                    plan.sum * 0.07 > support_plan_rate
                      ? plan.sum * 0.07
                      : support_plan_rate
                } else {
                  if (tmpCalc.support_plan === 'Silver') {
                    const support_plan_rate = 300 * conversionRatio
                    value =
                      plan.sum * 0.07 > support_plan_rate
                        ? plan.sum * 0.07
                        : support_plan_rate
                  }
                  if (tmpCalc.support_plan === 'Gold') {
                    const support_plan_rate = 1000 * conversionRatio
                    value =
                      plan.sum * 0.07 > support_plan_rate
                        ? plan.sum * 0.07
                        : support_plan_rate
                  }
                }
              }

              value = value * conversionRatio

              return {
                value,
                title: 'Support plan',
                info,
                infoMax: false,
              }
            })()

            return {
              ...plan,
              sum: plan.sum + support_plan.value,
              costs: { ...plan.costs, support_plan },
            }
          }

          return plan
        })
        .reduce((a, b) => (a.sum <= b.sum ? a : b))
      console.log(bestPlan) //eslint-disable-line
    }

    if (bestPlan) {
      plans[product] = bestPlan
    }

    sums[product] = tmpCosts
    totals[product] = Object.values(tmpCosts).reduce((a, b) => a + b, 0)

    if (Object.keys(bestPlan).length !== 0) {
      sums[product] = bestPlan.costs
      totals[product] += bestPlan.sum
    }
  })

  const monthly = Object.values(totals).reduce((a, b) => a + b, 0)
  const yearly = monthly * 12
  const yearly_per_device = yearly ? yearly / devices : 0

  dispatch({
    type: UPDATE_RESULTS,
    payload: {
      plans,
      sums,
      totals,
      total: {
        monthly,
        yearly,
        yearly_per_device,
      },
    },
  })
}

export const switchCurrency = () => dispatch => {
  dispatch({
    type: SWITCH_CURRENCY,
  })

  dispatch(updateResults())
}

export const reset = () => dispatch => {
  dispatch({
    type: RESET,
  })

  dispatch(updateResults())
}

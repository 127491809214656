import { connectRoutes } from 'redux-first-router'
import queryString from 'query-string'

import l10n from '../../shared/l10n'

import { FRONTPAGE, POST_TYPE_ARCHIVE, PRIMARY_TERM_ARCHIVE } from './routes'

const {
  lang,
  FilterableArchive: { postTypeURLSlugs: archivePostTypes },
} = l10n

const langPrefix = lang === 'en' ? '' : `/${lang}`

const archivePostTypesRegex = archivePostTypes.join('|')

export const routesMap = {
  [FRONTPAGE]: {
    path: '/',
  },
  [POST_TYPE_ARCHIVE]: {
    path: `${langPrefix}/(${archivePostTypesRegex})`,
  },
  [PRIMARY_TERM_ARCHIVE]: {
    path: `${langPrefix}/(${archivePostTypesRegex})/:primaryTermSlug`,
  },
}

const router = connectRoutes(routesMap, {
  querySerializer: queryString,
})

export const middleware = router.middleware
export const enhancer = router.enhancer

export default router.reducer

import $ from 'jquery'
import { sIsMobile } from '../redux/modules/browser'
import app from '../init/react/Bootstrapper'

const store = app.store

export default function shareImage() {
  $('.content article .img-wrapper img[data-share-text]')
    .toArray()
    .forEach(img => {
      const hrefEncoded = encodeURIComponent(window.location.href)

      const shareText = $(img).attr('data-share-text')
      const shareTextEncoded = encodeURIComponent(shareText)

      const imageWrapper = $(img).parent()

      $(imageWrapper).append(`
<div class="share">
    <i class="boschicons-share"/>
    <ul class="social-buttons">
        <li><i class="boschicons-twitter"/></li>
        <li><i class="boschicons-facebook"/></li>
        <li><i class="boschicons-linkedIn"/></li>
    </ul>
</div>
  `)

      const shareWrapper = $(imageWrapper).find('.share')
      const shareButton = $(imageWrapper).find('.boschicons-share')
      const socialButtons = $(imageWrapper).find('.social-buttons')

      const closeMenu = () => {
        $(shareWrapper).removeClass('dark')

        $(socialButtons)
          .stop(true, true)
          .fadeOut()
      }

      $(shareButton).on('mouseenter', () => {
        shareWrapper.addClass('dark')

        $(socialButtons)
          .stop(true, true)
          .fadeIn()
      })

      $(socialButtons).on('mouseleave', () => closeMenu())

      const state = store.getState()
      const isMobile = sIsMobile(state)
      if (isMobile) {
        $(shareButton).on('mouseleave', () => {
          closeMenu()
        })
      }

      const via = encodeURIComponent(' | via @Bosch_IO')

      $(imageWrapper)
        .find('.boschicons-twitter')
        .on('click', () => {
          window.open(
            `https://twitter.com/home?status=${shareTextEncoded}%0A${hrefEncoded}${via}`,
            'TwitterPopUp',
            'width=600,height=300'
          )
          closeMenu()
        })

      $(imageWrapper)
        .find('.boschicons-facebook')
        .on('click', () => {
          window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${hrefEncoded}`,
            'FacebookPopUp',
            'width=600,height=300'
          )
          closeMenu()
        })

      $(imageWrapper)
        .find('.boschicons-linkedIn')
        .on('click', () => {
          window.open(
            `https://www.linkedin.com/shareArticle?mini=true&url=${hrefEncoded}&title=${shareTextEncoded}&summary=&source=`,
            'LinkedInPopUp',
            'width=800,height=600'
          )
          closeMenu()
        })
    })
}

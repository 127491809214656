import { combineReducers } from 'redux'
import router from './router'
import browser from './browser'
import filterableArchiveSearchInput from './filterableArchiveSearchInput'
import options from './options'
import search from './search'
import calculator from './calculator'
import iotcalculator from './iotcalculator'

const appReducer = combineReducers({
  browser,
  location: router,
  filterableArchiveSearchInput,
  options,
  search,
  calculator,
  iotcalculator,
})

export default appReducer

const initialState = ''

const UPDATE = 'bos006/filterableArchiveSearchInput/UPDATE'

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE:
      return action.value

    default:
  }

  return state
}

export const update = value => ({ type: UPDATE, value })

import React from 'react'
import PropTypes from 'prop-types'

import SearchIcon from '../SearchIcon'
import SearchBar from '../SearchBar'
import $ from 'jquery'

var container = ''

if (
  $('body').hasClass('bosch-io') ||
  $('body').hasClass('bosch-io-track-and-trace')
) {
  container = 'container'
}

class LiveSearch extends React.Component {
  constructor() {
    super()

    this.handleOutsideClick = this.handleOutsideClick.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick, false)
    document.addEventListener('keydown', this.handleKeyDown, true)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false)
    document.removeEventListener('keydown', this.handleKeyDown, true)
  }

  handleOutsideClick(e) {
    if (this.node.contains(e.target) || !this.props.active) {
      return
    }

    this.props.deactivate()
  }

  handleKeyDown(e) {
    const { active, deactivate } = this.props
    if (!active) {
      return true
    }

    switch (e.key) {
      case 'Escape':
        e.preventDefault()
        deactivate()
        break
      default:
    }
  }

  render() {
    const { active } = this.props

    return (
      <div
        className={'live-search ' + (active ? 'active' : '')}
        ref={node => (this.node = node)}
      >
        <SearchIcon />
        <div className={'live-search__container ' + container}>
          <SearchBar />
        </div>
      </div>
    )
  }
}

LiveSearch.propTypes = {
  active: PropTypes.bool,
  deactivate: PropTypes.func,
}

export default LiveSearch

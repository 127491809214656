/* global System */
import $ from 'jquery'

$(document).ready(function() {
  if ($('.dhsv_vc_updates').length > 0) {
    System.import(
      /* webpackChunkName: "dhsv_vc_updates" */
      './updates'
    )
  }
})

/* global System */
import $ from 'jquery'

$(document).ready(function() {
  if ($('.dhsv_vc_teaser_slider').length > 0) {
    System.import(
      /* webpackChunkName: "dhsv_vc_teaser_slider" */
      './teaser_slider'
    )
  }
})

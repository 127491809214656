export const POST = 'bos001/routes/POST'

export const FRONTPAGE = 'bos001/routes/FRONTPAGE'
export const FRONTPAGE_PAGINATED = 'bos001/routes/FRONTPAGE_PAGINATED'

export const SEARCH = 'bos001/routes/SEARCH'
export const SEARCH_PAGINATED = 'bos001/routes/SEARCH_PAGINATED'

export const CATEGORY_ARCHIVE = 'bos001/routes/CATEGORY_ARCHIVE'
export const CATEGORY_ARCHIVE_PAGINATED =
  'bos001/routes/CATEGORY_ARCHIVE_PAGINATED'

export const AUTHORS = 'bos001/routes/AUTHORS'
export const AUTHORS_PAGINATED = 'bos001/routes/AUTHORS_PAGINATED'

export const POST_TYPE_ARCHIVE = 'bos006/routes/POST_TYPE_ARCHIVE'
export const PRIMARY_TERM_ARCHIVE = 'bos006/routes/PRIMARY_TERM_ARCHIVE'

export const sIsInitialPath = ({
  location: {
    prev: { pathname, type },
  },
}) => pathname === '' && type === ''
